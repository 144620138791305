import { getAuthParams } from '@config';
import { selectSessionID } from '@platform/selectors/context.selectors';
import store from '@store';
import { getTicketUid } from '@utils/storage';

const API_ENDPOINT = `${location.origin}/metrika/api/v1/page-tracking`;

const metricsApi = {
	endVisit: (options: MetricsOptions) => {
		return new Promise<void>(async resolve => {
			try {
				const request = formRequest(options);

				await fetch(`${API_ENDPOINT}/end-visit`, request);

				resolve();
			} catch (error) {
				console.log(error);
			}
		});
	},
	startVisit: (options: MetricsOptions) => {
		return new Promise<void>(async resolve => {
			try {
				const request = formRequest(options);

				await fetch(`${API_ENDPOINT}/start-visit`, request);

				resolve();
			} catch (error) {
				console.log(error);
			}
		});
	},
};

function formRequest(options: MetricsOptions) {
	const body = formRequestBody(options);
	const headers = {
		'Content-Type': 'application/json',
		Authorization: getTicketUid(),
	};

	return {
		body: JSON.stringify(body),
		headers,
		method: 'POST',
	};
}

function formRequestBody(options: MetricsOptions): MetricsRequest {
	const { params, path } = options;
	const { domain } = getAuthParams();
	const sessionID = selectSessionID(store.getState());

	return {
		domain,
		params: params.slice(1),
		path,
		sessionId: sessionID.toString(),
	};
}

type MetricsOptions = {
	params: string;
	path: string;
};

type MetricsRequest = {
	domain: string;
	params: string;
	path: string;
	sessionId: string;
};

export { metricsApi };
