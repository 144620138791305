/***
 * Library defines common constants for Alatyr Framework
 * @author skadnikov
 */

/***
 * Version number of static resources, changing in the process of development, used to avoid browser caching
 */
var staticResourcesVersion = 10;
/***
 * Name of the variable containing  security ticket UID {@link SecurityTicket#getTicketUID()}
 */
var TICKET_UID_VARIABLE = 'ticketuid';
/***
 * Name of the variable containing current pageflow for the rendered form
 */
var CURRENT_PAGEFLOW_VARIABLE = 'current_pageflow_name';
/***
 * Name of the variable containing current pageflow node name for the rendered form
 */
var CURRENT_PAGEFLOW_NODE_VARIABLE = 'current_pageflow_node_name';
/***
 * Name of the variable containing system name of the signal fired by the rendered form
 */
var SIGNAL_VARIABLE = 'signal_name';
/***
 * Name of the variable containing login of current user
 */
var USER_LOGIN_VARIABLE = 'user_login';
/***
 * Name of the variable containing password of current user
 */
var USER_PASSWORD_VARIABLE = 'user_password';
/***
 * Name of the variable containing display name of current user
 */
var USER_NAME_VARIABLE = 'user_name';
/***
 * Name of the variable containing display name of user active party
 */
var TENANT_NAME_VARIABLE = 'user_tenant_name';
/***
 * Name of the variable containing id of user active tenant
 */
var TENANT_ID_VARIABLE = 'user_tenant_id';
/***
 * Name of current business application
 */
var BIZ_APPLICATION_NAME_VARIABLE = 'biz_application_name';
/***
 * Name of the variable storing name of the new pageflow to start
 */
var START_NEW_PAGEFLOW_VARIABLE = 'start_new_pageflow';
/***
 * Name of the variable storing flag if user has admin rights
 */
var USER_IS_ADMINISTRATOR_VARIABLE = 'user_is_administrator';
/***
 * Name of the variable storing list of user security rights
 */
var USER_RIGHTS_LIST_VARIABLE = 'user_rights_list';
/***
 * ID of current business application
 */
var BIZ_APPLICATION_ID_VARIABLE = 'biz_application_id';
/***
 * Name of the variable storing root address of webservices
 */
var WEB_SERVICES_ROOT_VARIABLE = 'web_service_root';
/***
 * Stores email address of service provider
 */
var SERVICE_PROVIDER_EMAIL = 'support@seeneco.ru';
/***
 * Prefix of context parameter field name
 */
var WEB_FORM_CONTEXT_PARAM_FIELD_PREFIX = 'context-param-';
/***
 * Name of the variable storing ID of new active tenant for user
 */
var CHANGE_ACTIVE_TENANT_VARIABLE = 'change_active_tenant';
/***
 * Name of the variable storing ID of new active business application
 */
var CHANGE_ACTIVE_APPLICATION_VARIABLE = 'change_active_application';
/***
 * Name of the variable storing list of application components available to tenant
 */
var COMPONENTS_LIST_VARIABLE = 'components_list';

/***
 * Rights
 */
/***
 * System name of right allowing user to view list of tenants in system
 */
var RIGHT_TENANT_LIST = 'Security.Tenant.List';
/***
 * System name of right allowing user to view audit events
 */
var RIGHT_AUDIT_VIEW = 'Audit.View';
/***
 * System name of right allowing user to view a list of available processes
 */
var RIGHT_PROCESS_LIST = 'Process.List';
/***
 * System name of right allowing user to delete all tenant's data
 */
var RIGHT_TENANT_DATA_DELETION = 'Tenant.Data.Deletion';

var MIME_TYPES = {
	xlsx: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
	xml: 'application/xml',
	csv: 'text/csv',
	txt: 'text/plain',
	jpg: 'image/jpeg',
	jpeg: 'image/jpeg',
	png: 'image/png',
	gif: 'image/gif',
};

//Export for **Node.js**
if (typeof exports !== 'undefined') {
	var exportsContainer = {
		TICKET_UID_VARIABLE: TICKET_UID_VARIABLE,
		WEB_SERVICES_ROOT_VARIABLE: WEB_SERVICES_ROOT_VARIABLE,
		WEB_FORM_CONTEXT_PARAM_FIELD_PREFIX: WEB_FORM_CONTEXT_PARAM_FIELD_PREFIX,
		USER_LOGIN_VARIABLE: USER_LOGIN_VARIABLE,
		USER_PASSWORD_VARIABLE: USER_PASSWORD_VARIABLE,
	};
	for (var k in exportsContainer) {
		window[k] = exportsContainer[k];
		if (typeof module !== 'undefined' && module.exports) {
			exports[k] = exportsContainer[k];
		}
	}
}

var billStatusMap = {
	ISSUED: { name: 'Счет выставлен', textClass: 'muted' },
	SENT_TO_PAYMENT_GATEWAY: { name: 'Отправлен на оплату', textClass: 'text-info' },
	WAITING_FOR_PAYMENT_CONFIRMATION: { name: 'Ожидает оплаты', textClass: 'text-warning' },
	PAID: { name: 'Оплачен', textClass: 'text-success' },
	PAYMENT_FAILED: { name: 'Платеж завершен с ошибкой', textClass: 'text-error' },
};

const REQUEST_TYPE = {
	PAID: 4,
	PRESENT: 5,
	RENEW: 3,
	TRIAL_EXTRA: 2,
	TRIAL: 1,
};

const REQUEST_TYPE_NAME = {
	[REQUEST_TYPE.PAID]: 'Оплаченный период',
	[REQUEST_TYPE.PRESENT]: 'Подарок',
	[REQUEST_TYPE.RENEW]: 'Переподписка',
	[REQUEST_TYPE.TRIAL_EXTRA]: 'Дополнительный триал',
	[REQUEST_TYPE.TRIAL]: 'Триал',
};
