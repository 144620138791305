import styled, { css } from 'styled-components';

import { StickyProps } from './sticky';

type PixelProps = {
	initialTopShift: number;
};

const Root = styled.div<StickyProps>`
	position: sticky;
	z-index: 100;
	transition: background-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;

	${p => css`
		top: ${p.initialTopShift}px;

		&.sticky-active {
			${p.stickyActiveCss}
			background-color: ${p.theme.palette.space};
		}

		&.sticky-active.sticky-cover {
			${p.theme.fn.createBoxShadow(1)};
		}
	`}

	@media (max-height: 600px) {
		position: relative;
		top: 0 !important;
	}
`;

const Pixel = styled.div<PixelProps>`
	position: absolute;
	top: -1px;
	left: 50%;
	width: 1px;
	height: 1px;

	${p =>
		p.initialTopShift &&
		css`
			top: -${p.initialTopShift + 1}px;
		`}
`;

export { Root, Pixel };
