import React, { useState, useEffect, useRef, useMemo } from 'react';
import { css, FlattenSimpleInterpolation } from 'styled-components';

import { withTopShift } from '@core/hocs';
import { useResize } from '@core/hooks/use-resize';
import { useTheme } from '@theme';
import { Root, Pixel } from './styled';

export type StickyProps = {
	appearance?: 'stacking' | 'independent';
	initialTopShift?: number;
	style?: React.CSSProperties;
	stickyActiveCss?: FlattenSimpleInterpolation;
	disableWithTopShiftHoc?: boolean;
	children: React.ReactNode | ((options: StickyRenderOptions) => React.ReactNode);
	topShift?: number;
};

type StickyRenderOptions = {
	isIntersected: boolean;
};

const Sticky: React.FC<StickyProps> = props => {
	const { appearance, initialTopShift, topShift, style, stickyActiveCss, disableWithTopShiftHoc, children } = props;
	const [isIntersected, setIsIntersected] = useState(false);
	const { isDarkMode } = useTheme();
	const rootRef = useRef<HTMLDivElement>(null);
	const scope: { intersectionObserver: IntersectionObserver; isIntersected: boolean } = useMemo(
		() => ({ intersectionObserver: null, isIntersected }),
		[],
	);

	scope.isIntersected = isIntersected;

	useEffect(() => {
		runIntersectionObserver();
		refs.push(rootRef.current);
		perfotmTop();

		const disposeRefs = () => {
			const idx = refs.findIndex(x => x === rootRef.current);

			idx !== -1 && refs.splice(idx, 1);
		};

		return () => {
			disposeRefs();
			scope.intersectionObserver.disconnect();
		};
	}, [isDarkMode]);

	useResize(() => {
		perfotmTop();
	}, [isDarkMode]);

	useEffect(() => {
		perfotmTop();
	}, [topShift]);

	const perfotmTop = () => {
		effectTop({
			element: rootRef.current,
			initialTopShift,
			appTopShift: disableWithTopShiftHoc ? 0 : topShift,
			appearance,
		});
	};

	const runIntersectionObserver = () => {
		let intersectedRefs = [];

		scope.intersectionObserver = new IntersectionObserver(
			entries => {
				intersectedRefs = [];

				if (!rootRef.current) return;

				for (const entry of entries) {
					if (entry.intersectionRatio === 1) {
						for (const ref of refs) {
							ref.classList.remove(STICKY_ACTIVE);
						}
						rootRef.current.classList.remove(STICKY_ACTIVE);
					} else if (entry.intersectionRatio === 0) {
						for (const ref of refs) {
							appearance === 'stacking' && ref.classList.add(STICKY_ACTIVE);
							intersectedRefs.push(ref);
						}
					}
				}
				const isCurrentIntersected = refs.some(x => intersectedRefs.includes(x));

				if (isCurrentIntersected !== scope.isIntersected) {
					setIsIntersected(isCurrentIntersected);
				}
			},
			{ threshold: [0, 1] },
		);

		scope.intersectionObserver.observe(rootRef.current.querySelector('[data-sticky-pixel]'));
	};

	const renderChildren = () => {
		return typeof children === 'function'
			? (children as (options: StickyRenderOptions) => React.ReactNode)({ isIntersected })
			: children;
	};

	const calculatedTopShift = initialTopShift + (disableWithTopShiftHoc ? 0 : topShift);

	return (
		<Root ref={rootRef} initialTopShift={calculatedTopShift} stickyActiveCss={stickyActiveCss} style={style}>
			<Pixel data-sticky-pixel initialTopShift={calculatedTopShift} />
			{renderChildren()}
		</Root>
	);
};

Sticky.defaultProps = {
	appearance: 'stacking',
	initialTopShift: 0,
	style: {},
	stickyActiveCss: css`
		margin: 0 -40px;
		padding: 0 40px;
	`,
};

const refs: Array<HTMLElement> = [];

type EffectTopOtions = {
	element: HTMLElement;
	initialTopShift: number;
	appTopShift: number;
} & Pick<StickyProps, 'appearance'>;

function effectTop(options: EffectTopOtions) {
	const { element, initialTopShift, appTopShift, appearance } = options;
	const elementIdx = refs.findIndex(x => x === element);
	const filteredRefs = refs.filter((_, idx) => idx < elementIdx);
	const totalShift =
		appearance === 'independent'
			? initialTopShift + appTopShift
			: filteredRefs.reduce((acc, x) => (acc += x.clientHeight), 0) + (initialTopShift + appTopShift);

	element.style.setProperty('top', `${totalShift}px`);

	for (const ref of refs) {
		ref.classList.remove(STICKY_COVER);
	}

	if (elementIdx === refs.length - 1) {
		element.classList.add(STICKY_COVER);
	}
}

export const STICKY_COVER = 'sticky-cover';
export const STICKY_ACTIVE = 'sticky-active';

const XSticky = withTopShift()(Sticky) as React.ComponentClass<StickyProps>;

export { XSticky as Sticky };
