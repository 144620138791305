import React from 'react';
import { NavLink, RouteComponentProps, withRouter } from 'react-router-dom';

import { filterRoutes } from '@routes/urls';
import { IRenderRouteProps } from '@routes/workspace';
import { RenderTabOptions, TabPicker } from '@ui/tab-picker';
import { Sticky } from '../sticky';

export type NestedLinksProps = {
	parentRoute: IPageRoute & IRenderRouteProps;
} & RouteComponentProps<{}>;

type NestedLinksState = {
	dataSource: Array<NestedLinksItem>;
};

class NestedLinks extends React.PureComponent<NestedLinksProps, NestedLinksState> {
	state: NestedLinksState = {
		dataSource: [],
	};

	componentDidMount() {
		const { parentRoute } = this.props;
		const { availableComponents, userRights, nestedRoutes, isFullAccess } = parentRoute;
		const dataSource = filterRoutes(availableComponents, userRights, nestedRoutes, isFullAccess).map((route, idx) => ({
			value: idx,
			text: route.name,
			disabled: route.disabled,
			tooltipText: route.tooltipText,
			route,
		}));

		this.setState({ dataSource });
	}

	renderTab = ({ node, item }: RenderTabOptions<NestedLinksItem>) => {
		if (item.route.disabled) return node;

		return (
			<NavLink tabIndex={-1} to={item.route.path}>
				{node}
			</NavLink>
		);
	};

	render() {
		const { dataSource } = this.state;

		return (
			<Sticky initialTopShift={70}>
				<TabPicker
					value={this.props.location.pathname}
					dataSource={dataSource}
					marginLeft={40}
					getValue={getValue}
					detectIsDisabled={detectIsDisabled}
					getTooltipText={getTooltipText}
					renderTab={this.renderTab}
				/>
			</Sticky>
		);
	}
}

type NestedLinksItem = {
	route: IPageRoute;
};

const getValue = (x: NestedLinksItem) => x.route.path;

const detectIsDisabled = (x: NestedLinksItem) => x.route.disabled;

const getTooltipText = (x: NestedLinksItem) => x.route.tooltipText;

const XNestedLinks = withRouter<NestedLinksProps, any>(NestedLinks);

export { XNestedLinks as NestedLinks };
