// @ts-nocheck
import axios from 'axios';

import api from '@api';
import { getAuthParams } from '@config';
import { BillingProvider } from '@platform/model/billing';
import { selectTenantBusinessService } from '@platform/selectors/billing.selectors';
import { BASE_DATE_FORMAT } from '@shared/constants/time';
import store from '@store';
import * as moment from 'moment';
import * as _ from 'underscore';
import { tenantAccountLocalRoutes } from '../';
import createAction, { createStaticDataAction } from '../../../core/libs/action-creator';
import { getPropInSafe } from '../../../core/libs/utils';
import { ROUTE_MAP } from '../../../core/routes/urls';
import * as statuses from '../../shared/constants/statuses';
import { selectTenant } from '../selectors/tenant-account.selectors';

const TENANT_ACCOUNT_URL = ROUTE_MAP.TENANT_ACCOUNT;

const CHANGE_TARIFF_LEVEL = '[PLATFORM]: CHANGE_TARIFF_LEVEL';
const CREATE_PAYMENT_SERVICE_REQUEST = '[PLATFORM]: CREATE_PAYMENT_SERVICE_REQUEST';
const CREATE_PROMISED_PAYMENT_SERVICE_REQUEST = '[PLATFORM]: CREATE_PROMISED_PAYMENT_SERVICE_REQUEST';
const FETCH_ALL_TENANT_BILLS = '[PLATFORM]: FETCH_ALL_TENANT_BILLS';
const FETCH_BILLING_SERVICE_STATUS = '[PLATFORM]: FETCH_BILLING_SERVICE_STATUS';
const FETCH_TARIFFS = '[PLATFORM]: FETCH_TARIFFS';
const FETCH_TENANT_ACCOUNT = '[PLATFORM]: FETCH_TENANT_ACCOUNT';
const FETCH_TENANT_BILLS = '[PLATFORM]: FETCH_TENANT_BILLS';
const INIT_FAST_BILL = '[PLATFORM]: INIT_FAST_BILL';
const INVALIDATE_ALL_TENANT_BILLS = '[PLATFORM]: INVALIDATE_ALL_TENANT_BILLS';
const REGISTER_SERVICE_REQUEST = '[PLATFORM]: REGISTER_SERVICE_REQUEST';
const REVOKE_TENANT_BILL = '[PLATFORM]: REVOKE_TENANT_BILL';
const RUN_FAST_BILL_RESULT_EFFECT = '[PLATFORM]: RUN_FAST_BILL_RESULT_EFFECT';
const RUN_FORCE_SYNC_PAYMENT_STATUS_EFFECT = '[PLATFORM]: RUN_FORCE_SYNC_PAYMENT_STATUS_EFFECT';
const SELECT_TARIFF = '[PLATFORM]: SELECT_TARIFF';
const SEND_INVOICE_PAYMENT_TO_CMS = '[PLATFORM]: SEND_INVOICE_PAYMENT_TO_CMS';
const SET_STATUS_AFTER_BILLING = '[PLATFORM]: SET_STATUS_AFTER_BILLING';

const changeTariffLevelAction = createAction(CHANGE_TARIFF_LEVEL, (api, _, __, tariffLevelID: number) => {
	return new Promise<boolean>(resolve => {
		api.billingPack.billing
			.changeTariffLevel({
				tariffLevelID,
				dateStart: moment().format(BASE_DATE_FORMAT),
			})
			.then(result => {
				const isSuccess = Boolean(result && result.ID > 0);

				resolve(isSuccess);
			});
	});
});
const createPaymentServiceRequestAction = createAction(CREATE_PAYMENT_SERVICE_REQUEST, createPaymentServiceRequest);
const createPromisedPaymentServiceRequestAction = createAction(
	CREATE_PROMISED_PAYMENT_SERVICE_REQUEST,
	createPromisedPaymentServiceRequest,
);
const fetchAllTenantBillsAction = createAction(FETCH_ALL_TENANT_BILLS, fetchAllTenantBills);
const fetchBillingServiceStatusAction = createAction(FETCH_BILLING_SERVICE_STATUS, fetchBillingServiceStatus);
const fetchTariffsAction = createAction(FETCH_TARIFFS, fetchTariffs);
const fetchTenantAccountAction = createAction(FETCH_TENANT_ACCOUNT, fetchTenantAccount);
const fetchTenantBillsAction = createAction(FETCH_TENANT_BILLS, fetchTenantBills);
const invalidateAllTenantBillsAction = () => createStaticDataAction(INVALIDATE_ALL_TENANT_BILLS)();
const registerServiceRequestAction = createAction(REGISTER_SERVICE_REQUEST, registerServiceRequest);
const revokeTenantBillAction = createAction(REVOKE_TENANT_BILL, revokeTenantBill);
const selectTariffAction = (tariff: Tariff, tariffRate: TariffRate) =>
	createStaticDataAction(SELECT_TARIFF)({ tariff, tariffRate });
const sendInvoicePaymentToCMSAction = createAction(SEND_INVOICE_PAYMENT_TO_CMS, sendInvoicePaymentToCMS);

function createAndSubmitPaymentForm(url: string, parametrs: Record<string, string | number>) {
	const requestForm = document.createElement('form');

	requestForm.setAttribute('method', 'POST');
	requestForm.setAttribute('action', url);
	Object.keys(parametrs).forEach(key => {
		const input = document.createElement('input');

		input.setAttribute('type', 'hidden');
		input.setAttribute('name', key);
		input.setAttribute('value', parametrs[key].toString());
		requestForm.appendChild(input);
	});

	document.body.appendChild(requestForm);
	requestForm.submit();
}

function createPaymentServiceRequest(
	api,
	getState,
	dispatch,
	billID: number,
	paymentServiceCode: BillingProvider,
	params: { frID: number } = { frID: -1 },
) {
	const resultPage = `${TENANT_ACCOUNT_URL}/${tenantAccountLocalRoutes.contactData}`;

	return new Promise(resolve => {
		api.billingServiceClient.createPaymentServiceRequest(
			billID,
			paymentServiceCode,
			resultPage,
			(response: PaymentServiceRequest) => {
				resolve(response);
				const map = {
					[BillingProvider.YANDEX_KASSA]: () => {
						location.replace(response.ServiceURL);
					},
					[BillingProvider.ROBOKASSA]: () => {
						createAndSubmitPaymentForm(response.ServiceURL, response.Parameters);
					},
					[BillingProvider.SEENECO]: () => {
						sendPaymentWithRESTAPI(
							response.Parameters.invoice,
							response.Parameters.callbackParams,
							params.frID,
							billID,
						);
					},
				};

				map[paymentServiceCode] && map[paymentServiceCode]();
				console.log('PaymentServiceRequest', response);
			},
		);
	});
}

function createPromisedPaymentServiceRequest(api, _, __, tenantID: number) {
	const creditDaysCount = 3;

	return new Promise<void>(resolve => {
		api.billingServiceClient.promisePayment(tenantID, creditDaysCount, () => {
			resolve();
			location.replace(ROUTE_MAP.DASHBOARD);
		});
	});
}

function fetchAllTenantBills(api, getState, dispatch, tenantID: number, dateRange: DateRange) {
	return fetchTenantBillsMethod(tenantID, false, dateRange);
}

function fetchBillingServiceStatus(api, getState, dispatch, tenantID: number) {
	return fetchBillingServiceStatusMethod(tenantID);
}

function fetchBillingServiceStatusMethod(tenantID: number) {
	return new Promise<BillingServiceStatus>(resolve => {
		api.billingServiceClient.checkServiceStatus(tenantID, (status: BillingServiceStatus) => {
			resolve(status);
		});
	});
}

function fetchTariffs(api, getState, dispatch, tenantID: number) {
	return new Promise(resolve => {
		api.billingServiceClient.getTariffs(tenantID, (tariffs: Array<Tariff>) => {
			resolve(tariffs);
		});
	});
}

function fetchTenantAccount(api, getState, dispatch, tenantID: number) {
	return new Promise(resolve => {
		api.billingServiceClient.getTenantAccount(tenantID, (tenantAccount: TenantAccount) => {
			resolve(tenantAccount);
		});
	});
}

function fetchTenantBills(api, getState, dispatch, tenantID: number, onlyUnpaid = true) {
	return fetchTenantBillsMethod(tenantID, onlyUnpaid);
}

function fetchTenantBillsMethod(
	tenantID: number,
	onlyUnpaid = true,
	dateRange: DateRange = {
		dateStart: moment().startOf('year').subtract(1, 'year').format(BASE_DATE_FORMAT),
		dateEnd: moment().endOf('year').add(5, 'year').format(BASE_DATE_FORMAT),
	},
) {
	const businessService = selectTenantBusinessService(store.getState());
	const businessServiceID = businessService?.ID || getAuthParams().businessServiceID;

	return new Promise<Array<TenantBill>>(resolve => {
		const criteria = {
			...new api.billingpackage.TenantBillFilterCriteria(),
			BusinessServiceID: businessServiceID,
			OnlyUnpaid: onlyUnpaid,
			ServiceExpireDateFinish: dateRange.dateEnd,
			ServiceExpireDateStart: dateRange.dateStart,
			TenantIDList: tenantID.toString(),
		};

		api.billingServiceClient.getTenantBills(criteria, (bills: Array<TenantBill>) => {
			resolve(bills);
		});
	});
}

function initFastBillEffectAction(data: { email: string; frID: number }) {
	return createStaticDataAction(INIT_FAST_BILL)(data);
}

function registerServiceRequest(api, getState, dispatch, tenantID: number, tariffRateID: number, startDate: string) {
	return new Promise(resolve => {
		api.billingServiceClient.registerServiceRequest(tenantID, tariffRateID, startDate, (message: SuccessMessage) => {
			resolve(message);
		});
	});
}

function revokeTenantBill(api, getState, dispatch, billID: number) {
	return new Promise(resolve => {
		api.billingServiceClient.revokeTenantBill(billID, (result: boolean) => {
			resolve(result);
		});
	});
}

function runFastBillResultEffectAction(invoiceUID: string) {
	return createStaticDataAction(RUN_FAST_BILL_RESULT_EFFECT)(invoiceUID);
}

function runForceSyncPaymentStatusEffectAction() {
	return createStaticDataAction(RUN_FORCE_SYNC_PAYMENT_STATUS_EFFECT)();
}

function sendInvoicePaymentToCMS(api, getState, dispatch, invoiceUID: string, frID: number, billID: number) {
	return sendInvoicePaymentToCMSMethod(invoiceUID, frID, billID);
}

function sendInvoicePaymentToCMSMethod(sharedDocumentUID: string, frID: number, billID: number) {
	return new Promise(resolve => {
		const request = new api.fundspackage.CMSSendInvoiceRequest();

		request.DocumentUID = sharedDocumentUID;
		request.FundsRegisterID = frID;

		api.cashManagementSystemClient.sendInvoicePaymentToCMS(request, (response: CMSCreatePaymentDraftResponse) => {
			if (response.Status === statuses.SUCCESS) {
				updateTenantBillStatusMethod(billID, 'SENT_TO_PAYMENT_GATEWAY').then(() => {
					resolve(response);
				});
			} else {
				const message =
					response.AuthorizationSuccess === false
						? `Ошибка авторизации вызова при обращении к банку. ${response.Message || ''}`
						: response.ServiceAvailable === false
						? `Сервис на стороне банка недоступен. ${response.Message || ''}`
						: `${response.Message || 'Неизвестная ошибка при отправке платежа в банк. Обратитесь в службу поддержки.'}`;

				store.dispatch(
					setStatusAfterBillingAction({
						status: statuses.FAILED,
						message,
					}),
				);
			}
		});
	});
}

function sendPaymentWithRESTAPI(invoiceAsString: string, callbackParamsAsString: string, frID: number, billID: number) {
	const tenant = selectTenant(store.getState());
	const data = new FormData();
	const redirectUri = encodeURIComponent(
		`${location.origin}${TENANT_ACCOUNT_URL}/contact-data?paymentNotification=true`,
	);
	const payload = {
		invoice: invoiceAsString,
		callbackParams: `${callbackParamsAsString}&resultPage=${redirectUri}`,
		from: 'support@seeneco.com',
		to: tenant.ContactEMail,
		mailMessage: 'Счёт за оплату использования сервиса Seeneco',
		validateCounterpartyTaxCode: false,
	};

	Object.keys(payload).forEach(key => {
		data.append(key, payload[key]);
	});

	axios({
		method: 'post',
		headers: {
			'Content-Type': 'application/x-www-form-urlencoded',
		},
		url: `${location.origin}/snc-core-services/api/v2/invoices-from-issuer`,
		data,
	})
		.then(response => {
			console.log('sendPaymentWithAPI response', response);
			const apiInvoice: APIInvoice = response.data;

			store.dispatch(sendInvoicePaymentToCMSAction(apiInvoice.SharedDocumentUID, frID, billID));
		})
		.catch(error => {
			const errorType = getPropInSafe(error, o => o.response.data.errorType, '');
			const errorMessage = getPropInSafe(error, o => o.response.data.errorMessage, '');

			store.dispatch(
				setStatusAfterBillingAction({
					status: statuses.FAILED,
					type: errorType,
					message: errorMessage || `Ошибка ${error.response.status}`,
				}),
			);

			console.log('error.response', error.response);
		});
}

function setStatusAfterBillingAction(status: StatusAction) {
	return createStaticDataAction(SET_STATUS_AFTER_BILLING)(status);
}

function updateTenantBillStatusMethod(
	billID: number,
	status: 'SENT_TO_PAYMENT_GATEWAY' | 'WAITING_FOR_PAYMENT_CONFIRMATION',
) {
	return new Promise(resolve => {
		api.billingServiceClient.updateTenantBillStatus(billID, status, resolve);
	});
}

export const methods = {
	fetchBillingServiceStatusMethod,
	fetchTenantBillsMethod,
	sendInvoicePaymentToCMSMethod,
	updateTenantBillStatusMethod,
};

export {
	CHANGE_TARIFF_LEVEL,
	CREATE_PAYMENT_SERVICE_REQUEST,
	CREATE_PROMISED_PAYMENT_SERVICE_REQUEST,
	FETCH_ALL_TENANT_BILLS,
	FETCH_BILLING_SERVICE_STATUS,
	FETCH_TARIFFS,
	FETCH_TENANT_ACCOUNT,
	FETCH_TENANT_BILLS,
	INIT_FAST_BILL,
	INVALIDATE_ALL_TENANT_BILLS,
	REGISTER_SERVICE_REQUEST,
	REVOKE_TENANT_BILL,
	RUN_FAST_BILL_RESULT_EFFECT,
	RUN_FORCE_SYNC_PAYMENT_STATUS_EFFECT,
	SELECT_TARIFF,
	SEND_INVOICE_PAYMENT_TO_CMS,
	SET_STATUS_AFTER_BILLING,
	changeTariffLevelAction,
	createPaymentServiceRequestAction,
	createPromisedPaymentServiceRequestAction,
	fetchAllTenantBillsAction,
	fetchBillingServiceStatusAction,
	fetchTariffsAction,
	fetchTenantAccountAction,
	fetchTenantBillsAction,
	initFastBillEffectAction,
	invalidateAllTenantBillsAction,
	registerServiceRequestAction,
	revokeTenantBillAction,
	runFastBillResultEffectAction,
	runForceSyncPaymentStatusEffectAction,
	selectTariffAction,
	sendInvoicePaymentToCMSAction,
	setStatusAfterBillingAction,
};
